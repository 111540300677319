import React from 'react'
import Header from '../components/Header'

const ErrorPage = () => (
<div>
    <Header />
    <section className='error-page'>
        <h1 className='display-text'>Oh snap, it's a <span className='accent'>404</span>.</h1>
        <h2>It's probably because there's only one page to my site. Let's <a href='/'>turn around</a> and head back there.</h2>
    </section>
</div>
)

export default ErrorPage